// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/components/LoggedInPageSections/ChooseCategorySection.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/components/LoggedInPageSections/ChooseCategorySection.tsx");
  import.meta.hot.lastModified = "1732644193105.307";
}
// REMIX HMR END

import { useEffect, useRef, useState } from 'react';
import { CollectionCard } from '~common/components/collection/CollectionCard';
export function ChooseCategorySection({
  collections
}) {
  _s();
  const maxScrollWidth = useRef(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carousel = useRef(null);
  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prevState => prevState - 1);
    }
  };
  const moveNext = () => {
    if (carousel && carousel.current !== null && carousel.current?.offsetWidth * currentIndex <= maxScrollWidth.current) {
      setCurrentIndex(prevState => prevState + 1);
    }
  };
  const isDisabled = direction => {
    if (direction === 'prev') {
      return currentIndex <= 0;
    }
    if (direction === 'next' && carousel.current !== null) {
      return carousel.current?.offsetWidth * currentIndex >= maxScrollWidth.current;
    }
    return false;
  };
  useEffect(() => {
    if (carousel !== null && carousel.current !== null) {
      carousel.current.scrollLeft = carousel.current.offsetWidth * currentIndex;
    }
  }, [currentIndex]);
  useEffect(() => {
    maxScrollWidth.current = carousel.current ? carousel.current.scrollWidth - carousel.current.offsetWidth : 0;
  }, []);
  return <section id="choosecategory-section" aria-labelledby="choosecategory-section" className="pt-20 px-4 sm:pt-24 xl:mx-auto xl:max-w-7xl xl:px-8">
      <div className=" sm:px-6 lg:px-8 xl:px-0">
        <p className="text-dark-blue dark:text-primary-500 font-bold">
          Let's start?
        </p>
        <h2 id="category-heading" className="text-4xl font-bold tracking-tight text-gray-900 dark:text-slate-300">
          Choose a category below
        </h2>
      </div>

      <div className="-my-2 mt-4 flow-root">
        <div className="relative w-full px-2 py-2">
          <div className="flex flex-wrap justify-center gap-4 lg:justify-start">
            {collections.map(collection => <CollectionCard key={collection.id} collection={collection} detailUrl={`/collections/${collection.slug}`} />)}
          </div>
        </div>
        {/* **** carousel effect **** */}

        {/* <div className="box-content relative overflow-hidden py-8">
          <div
            ref={carousel}
            className="carousel-container relative w-full flex space-x-12 overflow-hidden scroll-smooth snap-x snap-mandatory touch-pan-x z-0"
          >
            {collections.map((collection) => (
              <CollectionCard
                key={collection.id}
                collection={collection}
                detailUrl={`/collections/${collection.slug}`}
              />
            ))}
          </div>
         </div>
         <div className="w-full flex justify-center mt-4">
          <NavigatorButtonGroup
            onPrev={movePrev}
            onNext={moveNext}
            isDisabled={isDisabled}
          />
         </div> */}
      </div>
    </section>;
}
_s(ChooseCategorySection, "1YVgoSRm2hbSgXrqvH8iVuupRds=");
_c = ChooseCategorySection;
var _c;
$RefreshReg$(_c, "ChooseCategorySection");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;