import { useCallback } from 'react'

export const useScrollToSection = (selectors: string) => {
  const scrollToSection = useCallback(() => {
    const section = document.querySelector(selectors)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [selectors])
  return scrollToSection
}
